import axios from 'axios';
import {
  requestOnAxiosFullfilled,
  responseOnAxiosFullfilled,
  responseOnReject,
} from './interceptor';

const graphqlVolta = axios.create({
  baseURL: process.env.REACT_APP_/ + '/graphql',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  method: 'POST',
});

graphqlVolta.interceptors.request.use(requestOnAxiosFullfilled);

graphqlVolta.interceptors.response.use(responseOnAxiosFullfilled, (err) =>
  responseOnReject(err, graphqlVolta)
);

export default graphqlVolta;
