import axios from 'axios';
import {
  requestOnAxiosFullfilled,
  responseOnAxiosFullfilled,
  responseOnReject,
} from './interceptor';

const graphqlSgb = axios.create({
  baseURL: process.env.REACT_APP_/_NODE_STB + '/graphql',
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  method: 'POST',
});

graphqlSgb.interceptors.request.use(requestOnAxiosFullfilled);

graphqlSgb.interceptors.response.use(responseOnAxiosFullfilled, (err) =>
  responseOnReject(err, graphqlSgb)
);

export default graphqlSgb;
