import {
  LOG_IN,
  LOG_OUT,
  REFRESH_TOKEN,
  SET_SESSION,
  SET_UNAUTHORIZED,
  UPDATE_SESSION,
} from '../extra-constants/auth';

const authInitialStates = {
  currentNavigation: 'auth',
  session: {},
  action: '',
};

function authReducer(state = authInitialStates, action) {
  const { type, payload } = action;
  const actions = {
    [LOG_IN]: () => ({
      ...state,
      currentNavigation: 'main',
      action: type,
    }),
    [LOG_OUT]: () => ({
      ...state,
      currentNavigation: 'auth',
      session: {},
      action: type,
    }),
    [SET_SESSION]: () => ({
      ...state,
      session: {
        ...state.session,
        [payload.key]: payload.data,
      },
      action: type,
    }),
    [UPDATE_SESSION]: () => ({
      ...state,
      session: {
        ...state.session,
        user: {
          ...state.session.user,
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        },
        expdate: payload.exp_date,
      },
      action: type,
    }),
    [REFRESH_TOKEN]: () => ({
      ...state,
      action: type,
    }),
    [SET_UNAUTHORIZED]: () => ({
      ...state,
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { authReducer };
