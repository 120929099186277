import axios from 'axios';
import {
  requestOnAxiosFullfilled,
  responseOnAxiosFullfilled,
  responseOnReject,
} from './interceptor';

const apiVolta = axios.create({
  baseURL: process.env.REACT_APP_/_GO,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

apiVolta.interceptors.request.use(requestOnAxiosFullfilled);

apiVolta.interceptors.response.use(responseOnAxiosFullfilled, (err) =>
  responseOnReject(err, apiVolta)
);

export default apiVolta;
