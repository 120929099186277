import { /_GO } from '@volta-redux/api';
import { logOut, setSession } from '@volta/module/auth/actions';
import moment from 'moment';
import { useState } from 'react';
import swal from 'sweetalert';

const LEAD_WITH_HTTP_REGEX = /^https?:\/\//;

export const range = (num) => {
  const result = [];
  for (let i = 0; i < num; i++) {
    result.push(i);
  }
  return result;
};

export const getImageUrl = (image, defaultBase = /_GO) => {
  let url = image;
  if (!LEAD_WITH_HTTP_REGEX.test(image)) {
    url = `${defaultBase}/${image}`;
  }
  return url;
};

export const setNegativeLatitude = (latitude, batteryCode) => {
  return latitude;
};

export const setPositiveLongitude = (longitude) => {
  return longitude;
};

export const formatDate = (date, format) => {
  return moment(date).format(format);
};

export const toUtc = (date) => {
  return moment.utc(date);
};

export const toLocalTime = (date) => {
  return moment.utc(date).local();
};

export const formatDateDefault = (cell) =>
  moment(cell).format('DD MMMM YYYY - HH:mm');

export const formatDateWithoutTime = (cell) =>
  moment(cell).format('DD MMMM YYYY');

export function toRupiah(string) {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  }).format(string);
}

export const getUniqueArrayObjectById = (arrayObject = [{}]) => {
  const uniqueIds = [];

  const unique = arrayObject.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id);
    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }
    return false;
  });

  return unique;
};

export const nullIfEmpty = (value) => {
  if (value === '' || value === null) {
    return null;
  }
  return value;
};

export const validateObject = (object) => {
  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).length > 0 ? object : undefined;
  }
  return undefined;
};

export const copyObjectWithoutValue = (object) => {
  const keys = Object.keys(object);
  const newObj = {};
  keys.forEach((key) => {
    newObj[key] = null;
  });
  return newObj;
};

export const useForm = (initialState = {}, isBlockingInitial = false) => {
  const [isBlocking, setIsBlocking] = useState(isBlockingInitial);
  const [formData, setFormData] = useState(initialState);
  const [errorList, setErrorList] = useState(
    copyObjectWithoutValue(initialState)
  );

  const onDataChange = (key, value, ignoreBlocking = false) => {
    if (!ignoreBlocking) {
      setIsBlocking(true);
    }
    setFormData({ ...formData, [key]: value });
    setErrorList({
      ...errorList,
      [key]: null,
    });
  };
  const onBatchDataChange = (changedData, ignoreBlocking = false) => {
    if (!ignoreBlocking) {
      setIsBlocking(true);
    }
    setFormData({ ...formData, ...changedData });

    const target = {};
    Object.keys(changedData).forEach((key) => (target[key] = null));
    setErrorList({
      ...errorList,
      ...target,
    });
  };

  const resetForm = () => {
    setIsBlocking(isBlockingInitial);
    setFormData(initialState);
    setErrorList(copyObjectWithoutValue(initialState));
  };
  return {
    formData,
    errorList,
    onDataChange,
    onBatchDataChange,
    setErrorList,
    isBlocking,
    resetForm,
  };
};

export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const isIot = (imeiIot) => {
  return imeiIot !== null && imeiIot !== '';
};

export function createSwalButtons(confirmText, cancelText) {
  return {
    ...(cancelText && {
      cancel: {
        text: cancelText,
        value: false,
        visible: true,
        className: 'btn-secondary rounded-pill',
        closeModal: true,
      },
    }),
    confirm: {
      text: confirmText,
      value: true,
      visible: true,
      className: 'btn-primary rounded-pill',
      closeModal: true,
    },
  };
}

// Helper function for handle unauthorized api request when using try-catch, call this function in catch block
export function isUnauthorizedHandler(dispatch, exeception) {
  if (exeception?.response?.status !== 401) return false;
  swal({
    title: 'Sesi login Anda telah habis',
    text: 'Harap login ulang.',
    icon: 'error',
    buttons: createSwalButtons('Oke'),
  }).then(() => {
    dispatch(
      setSession({
        key: 'user',
        data: null,
      })
    );
    dispatch(logOut());
  });

  return true;
}
